<template>
  <base-page-item>
    <v-timeline-item
      left
      small
      class="task-item pa-3"
      :color="active ? 'blue lighten-2' : 'grey lighten-2'"
      :icon-color="active ? 'blue lighten-2' : 'grey lighten-2'"
    >
      <template v-slot:opposite>
        <div>
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Email: </span>{{ email.email }}
          </div>
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Email to: </span>{{ email.to }}
          </div>
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Status: </span>
            <span :class="[color]">{{ email.status }}</span>
          </div>
        </div>
      </template>
      <v-row
        align="center"
        class="spacer"
        no-gutters
        justify="center"
      >
        <v-col cols="12">
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Created_at: </span> <br> {{ email.created_at }}
          </div>
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Captured_at: </span> <br>{{ email.captured_at }}
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>
  </base-page-item>
</template>

<script>
  import BasePageItem from '@/components/base/BasePageItem';

  export default {
    name: 'EmailItem',
    components: {
      BasePageItem,
    },
    props: {
      email: {
        type: Object,
        default: () => {},
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      color () {
        let colors = '';

        switch (this.email.status) {
        case 'sent':
          colors = 'green--text   text--lighten-1';
          break;
        default:
          colors = 'red--text  text--lighten-1';
          break;
        }

        return colors;
      },
    },
  };
</script>

<style scoped lang="scss">
.task-item {
  transition-duration: 0.5s;
  cursor: pointer;

  &:hover {
    background: rgba(black, 0.03);
  }
}
</style>
