<template>
  <base-selector-autocomplete
    :items="items"
    :initial-value="initialValue"
    :item-text="'provider'"
    item-value="_id"
    label="Select provider"
    @select="newSelect => $emit('select', newSelect, 'provider')"
  />
</template>

<script>
  import BaseSelectorAutocomplete from '@/components/base/selectors/BaseSelectorAutocomplete';

  export default {
    name: 'BaseProviderSelector',
    components: {
      BaseSelectorAutocomplete,
    },
    props: {
      initialValue: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        items: [
          { provider: 'all providers', _id: '' },
          { provider: 'microsoft', _id: 'microsoft' },
          { provider: 'smtp', _id: 'smtp' },
          { provider: 'google', _id: 'google' },
          { provider: 'sendgrid', _id: 'sendgrid' },
        ],
      };
    },
  };
</script>

<style scoped>

</style>
