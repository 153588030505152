<template>
  <div>
    <v-row class="sticky-filters">
      <v-col cols="3">
        <base-credentials-selector
          :initial-value="{_id: filters.credentials.value}"
          @select="selectFilter"
        />
      </v-col>
      <v-col cols="3">
        <base-provider-selector
          :initial-value="{_id: filters.provider.value}"
          @select="selectFilter"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="emailFilter"
          v-debounce:300ms="seachEmail"
          label="Search email"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-timeline clipped>
          <div
            v-for="(email, i) of emailsList"
            :key="email._id"
            @click="selectEmail(i)"
          >
            <email-item
              :email="email"
              :active="selectedEmail._id === email._id"
            />
          </div>
        </v-timeline>
      </v-col>
      <v-col cols="6">
        <prism-editor
          v-model="selectedEmailForEditor"
          :readonly="true"
          class="my-editor"
          :highlight="highlighter"
        />
      </v-col>
      <v-progress-linear
        v-if="loading"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      />
    </v-row>
  </div>
</template>

<script>
  import EmailItem from '@/components/emailsPage/EmailItem';
  import BaseCredentialsSelector from '@/components/base/selectors/BaseCredentialsSelector';
  import BaseProviderSelector from '@/components/base/selectors/BaseProviderSelector';
  import { mapGetters } from 'vuex';
  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css';

  import { lazyLoadMixin } from '@/mixins/lazyLoadMixin';

  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/themes/prism-tomorrow.css';

  export default {
    name: 'Emails',
    components: {
      EmailItem,
      PrismEditor,
      BaseCredentialsSelector,
      BaseProviderSelector,
    },
    mixins: [lazyLoadMixin],
    data () {
      return {
        selectedEmailForEditor: 'Select email',
        selectedEmail: '',
        emailFilter: '',
      };
    },
    computed: {
      ...mapGetters({
        emailsList: 'emails/emailsList',
        filters: 'emails/filters',
      }),
    },
    methods: {
      handleScroll: async function () {
        const bottomOfWindow =
          document.documentElement.scrollTop.toFixed() + window.innerHeight.toFixed() >=
          document.documentElement.offsetHeight;
        if (bottomOfWindow && this.canLoadMore && !this.loading) {
          this.loading = true;
          const count = this.emailsList.length;
          await this.$store.dispatch('emails/LOAD_EMAILS_LIST', count);
          this.canLoadMore = this.emailsList.length > count;
          this.loading = false;
        }
      },
      highlighter (code) {
        return highlight(code, languages.js); // returns html
      },
      selectEmail (index) {
        this.selectedEmail = this.emailsList[index];
        this.selectedEmailForEditor = JSON.stringify(
          this.emailsList[index],
          null,
          '\t'
        ).replace(/"([^"]+)":/g, '$1:');
      },
      async selectFilter (value, filter) {
        this.$store.commit('emails/SET_FILTER', { key: filter, value: { value: value._id } });
        await this.$store.dispatch('emails/LOAD_EMAILS_LIST');
      },
      async seachEmail () {
        this.$store.commit('emails/SET_FILTER', { key: 'emailFilter', value: this.emailFilter });
        await this.$store.dispatch('emails/LOAD_EMAILS_LIST');
      },
    },
  };
</script>

<style scoped>
.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 25px;

  position: fixed;
  top: 150px;
  height: 75vh;
  width: 60vw;
  z-index: 1;
  white-space: pre-wrap;
}

.prism-editor__textarea:focus {
  outline: none;
}
.sticky-filters {
  position: sticky;
  top: 60px;
  margin-left: 8px;
  background: #fff;
  z-index: 3;
}
</style>
