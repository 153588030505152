import { render, staticRenderFns } from "./EmailItem.vue?vue&type=template&id=2e58a959&scoped=true&"
import script from "./EmailItem.vue?vue&type=script&lang=js&"
export * from "./EmailItem.vue?vue&type=script&lang=js&"
import style0 from "./EmailItem.vue?vue&type=style&index=0&id=2e58a959&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e58a959",
  null
  
)

export default component.exports